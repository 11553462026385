import { darken, lighten, shade } from "polished"

const primaryColor = "#3F499F"
const secondaryColor = "#d03838"
const thirdColor = "#005acf"
const textColor = "#595959"
const secondaryBgColor = "#F6F9FF"
// rgba(220, 223, 247, 0.55)
const secondaryColorAlfa = "rgba(63, 73, 159, 0.81)"

export const theme = {
  name: "Default",
  font: {
    primary: "'Raleway', sans-serif",
    secondary: "'Roboto', sans-serif",
  },
  color: {
    background: "#ffffff",
    secondaryBG: secondaryBgColor,
    primary: primaryColor,
    secondary: secondaryColor,
    third: thirdColor,
    secondaryAlfa: secondaryColorAlfa,
    white: "#ffffff",
    text: textColor,
    gray: "rgba(0, 0, 0, 0.12)",
    primaryGradient: `linear-gradient(95.36deg, ${lighten(
      0.05,
      primaryColor
    )} 16.39%, ${shade(0.18, darken(0, primaryColor))} 130.71%)`,
    secondaryGradient: `radial-gradient(91.34% 113.52% at 81.15% -18.29%, rgba(96, 152, 225, 0.31) 51.6%, rgba(220, 223, 247, 0.1705) 93.23%)`,
  },
  borderRadius: "20px",
  spacing: {
    sectionMarginTop: "14rem",
  },
  boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.19)",
  transition: "all 0.3s linear",
  breakpoints: {
    xss: "375px",
    xs: "425px",
    sm: "600px",
    md: "960px",
    lg: "1280px",
    xl: "1920px",
  },
}
