import React from "react"
import styled from "styled-components"

export const NavigationStyled = styled.div`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;

  a {
    margin: 0 14px;
    color: ${({ theme }) => theme.color.third};
    padding: 5px;
    padding-bottom: 10px;
    font-size: 18px;

    &.active {
      border-bottom: 2px ${({ theme }) => theme.color.primary} solid;
      color: ${({ theme }) => theme.color.primary};
    }
  }

  button {
    margin: 0;
    margin-left: 0;
    span {
      a {
        color: ${(props) => props.theme.color.white};
        padding: 0;

        &.active {
          border: none;
        }
      }
    }
  }

  @media (max-width: 980px) {
    display: none;
  }
`

const Navigation = ({ children }) => {
  return <NavigationStyled>{children}</NavigationStyled>
}

export default Navigation
