import React from "react"
import styled from "styled-components"

export const StyledBurger = styled.div`
  position: absolute;
  top: 30px;
  right: 2rem;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 1.8rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  @media (max-width: 360px) {
    top: 25px;
  }
  @media (max-width: 980px) {
    display: flex;
  }

  &:focus {
    outline: none;
  }

  div {
    width: 100%;
    height: 0.15rem;
    background: ${({ theme, open }) => (open ? theme.color.secondary : theme.color.primary)};
    border-radius: 0px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

export interface HamburgerProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const Hamburger = (props: HamburgerProps) => {
  const { open, setOpen } = props

  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)} id="hamburger">
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}
export default Hamburger
