export interface RouteLink {
  name: string
  to: string
  activeClassName?: string
}

export const routes: RouteLink[] = [
  {
    name: "Domov",
    to: "/",
    activeClassName: "active",
  },
  {
    name: "Kurzy",
    to: "/kurzy",
  },
  {
    name: "Vozidlá",
    to: "/vozidla/",
  },
  {
    name: "Výcvik",
    to: "/vycvik/",
  },
  {
    name: "Galéria",
    to: "/galeria",
  },
  {
    name: "Dokumenty",
    to: "/dokumenty",
  },

  {
    name: "FAQ",
    to: "/faq",
  },
  {
    name: "Kontakt",
    to: "/kontakt",
  },

  {
    name: "Prihláška",
    to: "/prihlaska",
  },
]
