import React from "react"
import {QueryClient, QueryClientProvider} from "react-query"
import {createGlobalStyle, ThemeProvider} from "styled-components"
import {muiTheme} from "../../../lib/theme"
import {theme} from "../Theme/Theme"
import Footer from "./components/Footer/Footer"
import Header from "./components/Header/header"
import {UserProvider} from "@/lib/context/UserContext";


const GlobalSstyle = createGlobalStyle`

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    font-family: ${(props) => props.theme.font.primary};
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
    color: red;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    color: ${(props) => props.theme.color.primary};
    font-family: ${(props) => props.theme.font.secondary};
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;
  }

  a:active,
  a:hover {
    outline-width: 0;
    color: #7480e2;
    transition: ${(props) => props.theme.transition};
  }

  button {
    border-radius: 0 !important;

    span {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      font-family: ${(props) => props.theme.font.primary};
    }
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: ${(props) => props.theme.font.secondary};
    font-size: 18px;
    line-height: 26px;
    color: ${(props) => props.theme.color.text};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.xs}) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  h1, h2, h3, h4, h5, h6, .upTitle {
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.color.primary};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: bold;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-style: normal;
  }

  h1 {
    font-family: ${(props) => props.theme.font.primary};
    font-style: normal;
    margin: 10px 0;
    font-weight: bold;
    font-size: 55px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.xs}) {
      margin: 2px 0;
      font-size: 45px;
    }

    ${muiTheme.breakpoints.down("md")} {
      font-size: 40px;
    }

    ${muiTheme.breakpoints.down("sm")} {
      font-size: 30px;
    }
  }

  h2 {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 45px;
    line-height: 1.1;

    ${muiTheme.breakpoints.down("md")} {
      font-size: 35px;
    }

    ${muiTheme.breakpoints.down("sm")} {
      font-size: 25px;
    }
  }

  h3 {
    margin: 10px o;
    font-size: 25px;
    line-height: 1.1;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.xs}) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  h4 {
    margin: 10px 0;
    color: ${(props) => props.theme.color.secondary};
    font-size: 24px;
    line-height: 1.1;
    text-transform: uppercase;

    ${muiTheme.breakpoints.down("sm")} {
      font-size: 18px;
      line-height: 24px;
    }
  }

  h6 {
    font-family: ${(props) => props.theme.font.primary};
  }

  .upTitle {
    margin: 2px 0;
    color: ${(props) => props.theme.color.secondary};
    font-size: 18px;
    text-transform: uppercase;
    line-height: 18px;

    ${muiTheme.breakpoints.up("md")} {
      font-size: 24px !important;
      line-height: 24px;
    }
  }

  section {
    ${muiTheme.breakpoints.down("md")} {
      overflow: hidden;
    }
  }

  .upper {
    text-transform: uppercase;
  }

  ul {
    margin: 10px 0;
    list-style-type: disc;
    list-style-position: inside;

  }


`

const queryClient = new QueryClient()

const Layout = ({children, fluid = false}) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalSstyle/>
        <Header/>
        <QueryClientProvider client={queryClient}>
          <UserProvider>

            <main
              style={{
                maxWidth: fluid ? "none" : "1600px",
                margin: "auto",
              }}
            >
              {children}
            </main>
          </UserProvider>

        </QueryClientProvider>
        <Footer/>
      </ThemeProvider>
    </>
  )
}

export default Layout
