import React from "react"
import styled from "styled-components"
import { routes } from "./routes"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import { Grid } from "@material-ui/core"
import NavigationLinks from "./NavigationLinks"


export const StyledMenu = styled.div`
  background: ${({ theme }) => theme.color.primary};
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-120%)")};
  z-index: 10;
  padding: 30px 0;
  width: 100vw;
  height: 100vh;

  @media (max-width: 440px) {
    width: 100%;
  }

  a {
    font-size: 20px;
    padding: 0 10px;
    font-weight: bold;
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;
    transition: color 0.3s linear;
    padding-bottom: 10px;
    margin-bottom: 12px;

    &.active {
      border-bottom: 2px ${({ theme }) => theme.color.secondary} solid;
      color: ${({ theme }) => theme.color.white};
    }

    @media (max-width: 440px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  button {
    span {
      a {
        padding: 0;
        margin: 0;
      }
    }
  }
`
const StyledGrid = styled(Grid)`
  height: 100%;
  padding-bottom: 30px;
`

const MobileMenu = ({ open }) => {
  return (
    <StyledMenu open={open} id="mobile_menu">
      <StyledGrid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%" }}
        spacing={1}
      >
        <NavigationLinks />
      </StyledGrid>
    </StyledMenu>
  )
}

export default MobileMenu
