import React from "react"
import styled from "styled-components"

interface Props {
  fluid: boolean
  className?: string
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${(props) => (props.fluid ? "100%" : "1440px")};
  padding: 0 20px;
  height: 100%;
`
const Container: React.FC<Props> = (props) => {
  return (
    <Wrapper {...props} className={props.className}>
      {props.children}
    </Wrapper>
  )
}

export default Container
