import Button from "@material-ui/core/Button/Button"
import { Link } from "gatsby"
import React from "react"
import { routes } from "./routes"

const NavigationLinks = () => {
  return routes.map((route) => {
    if (route.to === "/prihlaska") {
      return (
        <Link to="/prihlaska" key={route.to}>
          <Button variant="contained" color="primary">
            e-Prihláška
          </Button>
        </Link>
      )
    } else {
      return (
        <Link key={route.to} to={route.to} activeClassName="active">
          {route.name}
        </Link>
      )
    }
  })
}

export default NavigationLinks
