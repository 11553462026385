import { Grid } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import { FaFacebookF, FaInstagram } from "react-icons/fa"
import styled from "styled-components"
import Container from "../Container/container"
import { routes } from "./routes"

const StyledFooter = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.color.gray};
`
const InnerStyledFooter = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  color: #3f499f;
  overflow: hidden;
`

const FooterLinks = styled.div`
  font-size: 18px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > a {
    margin: 0 10px;
  }
`
const FooterRights = styled.div``

const SocialLink = styled.a`
  transition: all 0.2s linear;
  &:hover {
    transform: scale(0.9);
    transition: all 0.2s linear;
  }

  img {
    width: 20px;
    height: 20px;
  }
`

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <StyledFooter>
      <Container fluid={false}>
        <InnerStyledFooter>
          <Grid container justify="space-between" alignItems="center">
            <FooterLinks>
              {routes.map((route) => {
                const link = route.to ? route.to : `/${route.name.toLowerCase()}/`
                return (
                  <Link key={link} to={link}>
                    {route.name}
                  </Link>
                )
              })}
              <SocialLink
                href="https://www.facebook.com/Auto%C5%A1kola-HRON-323885391473000/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/autoskolahron/" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </SocialLink>
            </FooterLinks>
            <FooterRights>
              <p>© {new Date().getFullYear()} | Autoškola Hron - všetky práva vyhradené</p>
            </FooterRights>
          </Grid>
        </InnerStyledFooter>
      </Container>
    </StyledFooter>
  )
}

export default Footer
