import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Container from "../Container/container"
import Hamburger from "./hamburger"
import MobileMenu from "./mobileNavigation"
import Navigation from "./Navigation"
import NavigationLinks from "./NavigationLinks"

export interface RouteLink {
  name: string
  to?: string
}

const LogoWrapper = styled.div`
  margin-right: 7px;
  height: 100%;
  max-width: 150px;
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  @media (max-width: 360px) {
    max-width: 100px;
  }
`

const LogoLink = styled(Link)`
  width: 80%;
  display: flex;
  flex-flow: column;
  justify-items: center;
  /* margin-top: 10px; */
`

export const CustomHeader = styled.header`
  background-color: white;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  z-index: 100;
  top: 0;
  left: 0;
  transition: all 0.3s linear;

  @media (max-width: 360px) {
    height: 65px;
  }

  &.sticky {
    background-color: white;
    /* position: fixed; */
    display: flex;
    top: 0;
    width: 100%;
    transition: all 1s ease-in;

    box-shadow: ${({ theme }) => theme.boxShadow};
    animation: moveDown 0.5s ease-in;
  }

  .header_container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }
`

const useIsSticky = (el) => {
  const [isSticky, setIsSticky] = useState(false)
  useEffect(() => {
    const header = document.getElementById(el)
    const sticky = header.offsetTop
    const scrollCallBack = () => (window.pageYOffset > sticky ? setIsSticky(true) : setIsSticky(false))

    window.addEventListener("scroll", scrollCallBack)
    return () => {
      window.removeEventListener("scroll", scrollCallBack)
    }
  }, [])
  return isSticky
}

const Header = () => {
  const data = useStaticQuery(graphql`
    query Logo {
      image: file(relativePath: { eq: "logo_Autoskola_Hron.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const isSticky = useIsSticky("customHeader")
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [open])

  return (
    <CustomHeader id="customHeader" className={isSticky ? "header sticky" : "header"}>
      <Container fluid={false} className="header_container">
        <LogoWrapper>
          <LogoLink to="/">
            <Img className="flex" fluid={data.image.childImageSharp.fluid} alt="Logo autoškola Hron" />
          </LogoLink>
        </LogoWrapper>

        <Navigation>
          <NavigationLinks />
        </Navigation>
        <MobileMenu open={open} aria-hidden={!open} />
        <Hamburger open={open} setOpen={setOpen} />
      </Container>
    </CustomHeader>
  )
}

export default Header
