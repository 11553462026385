export interface RouteLink {
  name: string
  to?: string
  activeClassName?: string
}

export const routes: RouteLink[] = [
  // {
  //   name: "Spracovanie osobných údajov",
  //   to: "/gdpr"
  // },

  {
    name: "Partneri",
    to: "/partneri"
  },
]
